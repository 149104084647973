
<template>
  <div>
    <el-card class="container">
      <div class="detail-title">收件信息</div>
      <el-form>
        <el-form-item style="width: 60%" label="收件人名称">
          <div class="value">{{ orderDetail.expressOrderAddressDTO.name }}</div>
        </el-form-item>
        <el-form-item style="width: 60%" label="收件人电话">
          <div class="value">{{ orderDetail.expressOrderAddressDTO.mobile }}</div>
        </el-form-item>

        <el-form-item style="width: 60%" label="收货省市区">
          <div class="value">{{ orderDetail.expressOrderAddressDTO.area }}</div>
        </el-form-item>
        <el-form-item style="width: 60%" label="收货地址">
          <div class="value">{{ orderDetail.expressOrderAddressDTO.detailedAddress }}</div>
        </el-form-item>
      </el-form>
      <div class="detail-title">物流详情</div>
      <el-form>
        <el-form-item style="width: 60%" label="物流公司">
          <div class="value">{{ orderDetail.expressParcelDTOList[$route.query.index].expressCorporationName }}</div>
        </el-form-item>
        <el-form-item style="width: 60%" label="物流单号">
          <div class="value">{{ orderDetail.expressParcelDTOList[$route.query.index].expressNumber }}</div>
        </el-form-item>
      </el-form>

      <bm-timeline>
        <bm-timelineitem
          v-for="(activity, index) in traceList"
          :key="index"
          :icon="activity.icon"
          :type="activity.type"
          :color="activity.color"
          :size="activity.size"
          :timestamp="activity.acceptTime"
        >
          {{ activity.acceptStation }}
        </bm-timelineitem>
      </bm-timeline>
    </el-card>
  </div>
</template>


<script>
import BmTimeline from '@/component/time-line'
import BmTimelineitem from '@/component/timeline-item'
export default {
  components: { BmTimelineitem, BmTimeline },
  data() {
    return {
      orderDetail: {},
      traceList: [
        {
          content: '支持使用图标',
          timestamp: '2018-04-12 20:46',
          size: 'large',
          type: 'primary'
        },
        {
          content: '支持自定义颜色',
          timestamp: '2018-04-03 20:46',
          color: '#0bbd87'
        },
        {
          content: '支持自定义尺寸',
          timestamp: '2018-04-03 20:46',
          size: 'large'
        },
        {
          content: '默认样式的节点',
          timestamp: '2018-04-03 20:46'
        }
      ]
    }
  },
  created() {
    this.orderDetail = JSON.parse(sessionStorage.orderDetail)
    console.log(this.orderDetail)
    this.getTrace()
  },
  methods: {
    getTrace() {
      this.$http
        .get('/boom-mix-biz-service/sysAdmin/order/express/delivery/trace/list', {
          params: {
            shipperCode: this.orderDetail.expressParcelDTOList[this.$route.query.index].expressCorporationCode,
            phoneNumber: this.orderDetail.expressOrderAddressDTO.mobile,
            logisticCode: this.orderDetail.expressParcelDTOList[this.$route.query.index].expressNumber
          }
        })
        .then(res => {
          let state = res[0].action
          let origin = 'https://file.mall.tanchi.shop/miniapp/icon/express/logistics/status/'
          this.traceList = res.map(item => {
            if (item.action == 1) {
              item.icon = origin + 'get.png'
              if (state == item.action) {
                item.icon = 'get_active.png'
              }
            } else if (item.action == 202) {
              item.icon = origin + 'delivery.png'

              if (state == item.action) {
                item.icon = origin + 'delivery_active.png'
              }
            } else if (item.action == 2) {
              // if (!isTransport) {
              //   item.icon = origin +   'transport.png'
              //   item.activeIcon = 'transport_active.png'
              //   isTransport = true
              // } else {
              item.icon = origin + 'dot.png'
              // }
            } else if (item.action == 311) {
              item.icon = origin + 'fail.png'
              if (state == item.action) {
                item.icon = origin + 'fail_active.png'
              }
            } else if (item.action[0] == 3) {
              item.icon = origin + 'done.png'

              if (state == item.action) {
                item.icon = origin + 'done_active.png'
              }
            } else if (item.action == 404) {
              item.icon = origin + 'reject.png'
              if (state == item.action) {
                item.icon = origin + 'reject_active.png'
              }
            } else if (item.action[4] == 4) {
              item.icon = origin + 'error.png'

              if (state == item.action) {
                item.icon = origin + 'error_active.png'
              }
            } else {
              item.icon = origin + 'dot.png'

              if (state == item.action) {
                item.icon = origin + 'dot.png'
              }
            }
            // item.icon = origin +   '@/asset/status/' + 'delivery_active.png'

            return item
          })

          console.log(this.traceList)
        })
        .catch(err => {
          // this.$$message
        })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  margin: @container-margin;
  padding: 20px;

  .detail-title {
    margin-bottom: 10px;
  }

  .value {
    font-weight: bold;

    .btn {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      color: #e7141a;
      margin-left: 15px;
    }
  }
}
</style>
