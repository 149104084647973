<template>
  <li class="el-timeline-item">
    <div class="el-timeline-item__tail"></div>

    <div
      v-if="!$slots.dot"
      class="el-timeline-item__node"
      :class="[`el-timeline-item__node--${size || ''}`, `el-timeline-item__node--${type || ''}`]"
      :style="{
        backgroundColor: color
      }"
    >
      <img style="width: 40px; height: 40px" :src="icon" />
    </div>
    <div v-if="$slots.dot" class="el-timeline-item__dot">
      <slot name="dot"></slot>
    </div>

    <div class="el-timeline-item__wrapper">
      <div v-if="!hideTimestamp && placement === 'top'" class="el-timeline-item__timestamp is-top">
        {{ timestamp | formatTime }}
      </div>

      <div class="el-timeline-item__content">
        <slot></slot>
      </div>

      <div v-if="!hideTimestamp && placement === 'bottom'" class="el-timeline-item__timestamp is-bottom">
        {{ timestamp | formatTime }}
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'BmTimelineitem',
  inject: ['timeline'],
  props: {
    timestamp: String,

    hideTimestamp: {
      type: Boolean,
      default: false
    },

    placement: {
      type: String,
      default: 'bottom'
    },

    type: String,

    color: String,

    size: {
      type: String,
      default: 'normal'
    },

    icon: String
  }
}
</script>
